import React from 'react'
import HeroBanner from './Banner'
import DataGrid from './DataGrid';

const InspirationSubCategory = () => {
  return (
    <>
      <HeroBanner />
      <DataGrid/>
    </>
  );
};

export default InspirationSubCategory;
