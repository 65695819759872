import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import React, { useEffect, Suspense, lazy } from 'react';
import { Global, css } from '@emotion/react';
import 'focus-visible/dist/focus-visible'
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import FloatingWhatsAppButton from '../src/components/Navbar/FloatingWhatsapp';
import './App.css';
import AllRoutes from './routes/AllRoutes';
import './styles/font.css';
import { BrowserRouter } from 'react-router-dom';

// Lazy load components
const Navbar = lazy(() => import('./components/Navbar/Navbar'));
const Footer = lazy(() => import('./components/Navbar/Footer'));
const NewsLetter = lazy(() => import('./components/Navbar/NewsLetter'));
const SitemapGenerator = lazy(() => import('./components/Sitemap/SitemapGenerator'));

const theme = extendTheme({
  styles: {
    global: {
      body: {
        margin: 0,
        padding: 0
      },
      a: {
        textDecoration: 'none !important',
      },
      '.textDecoration': {
        textDecoration: 'underline !important',
      },
      
    }
  }
});

const GlobalStyles = css`
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;

function App() {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-WWP7QZNX'
    };
    if (!window.dataLayer) {
      TagManager.initialize(tagManagerArgs);
    }
    TagManager.initialize(tagManagerArgs);

    if (window.location.hostname === 'wearetenet.com') {
      window.location.replace(`https://www.wearetenet.com${window.location.pathname}`);
    }

    if (window.location.pathname.endsWith('/') && window.location.pathname !== '/') {
      const newPathname = window.location.pathname.slice(0, -1);
      window.location.replace(newPathname);
    }
  }, []);

  const isHomePage = window.location.pathname === '/';
  const isSitemapPage = window.location.pathname === '/sitemap';

  return (
    <HelmetProvider>
      <ChakraProvider theme={theme}>
        <Suspense fallback={null}>
          <Navbar isHomePage={isHomePage} />
          <div>
            {isSitemapPage ? <SitemapGenerator /> : <AllRoutes />}
          </div>

          {/* <ProActiveEngagement /> */}
          {/* <WebsiteExitOfferPopup /> */}

          <NewsLetter />
          <Footer />
          <FloatingWhatsAppButton />
        </Suspense>
      </ChakraProvider>
    </HelmetProvider>
  );
}

export default App;
