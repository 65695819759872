import { Box, Container, Text, Heading } from '@chakra-ui/react';
import Breadcrumb from './Breadcrumb';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
const HeroBanner = () => {
  const { InspirationID } = useParams();
  const [data, setData] = useState(null); // Change to null for better initial state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await axios.get(
          `https://cms.wearetenet.com/api/inspirations?populate=deep&filters[Slug]=${InspirationID}`
        );
        if (resp.data.data.length > 0) {
          setData(resp.data.data[0]); // Get the first matching item
        } else {
          console.error('No data found for this LocationId');
        }
      } catch (err) {
        console.error('Data Not Found: ' + err);
      }
    };
    fetchData();
  }, [InspirationID]);
  return (
    <Box
      id="hero-section"
      padding={[
        '51px 0 80px',
        '51px 0 80px',
        '51px 0 80px',
        '49px 0 115px',
        '49px 0 115px',
        '49px 0 115px',
      ]}
      overflow="hidden"
      position="relative"
      backgroundColor={'#111111'}
    >
      <Container
        maxWidth={['100%', '100%', '100%', '91%', '91%', '91%']}
        m="0 auto"
        zIndex={2}
        position="relative"
        padding={['0 20px', '0 20px', '0 20px', '0', '0', '0']}
      >
        <Breadcrumb />
        <Box paddingTop={['0px', '0px', '0px', '58px', '58px', '58px']}>
          <Box textAlign={'center'} maxW={['1125px']} margin={'0 auto'}>
            <Heading
              as={'h1'}
              fontSize={['36px', '36px', '36px', '68px', '68px', '68px']}
              color={'#fff'}
              fontFamily={'var(--garnett-semibold)'}
              fontWeight={'600'}
              lineHeight={'normal'}
              paddingBottom={['22px', '22px', '22px', '24px']}
            >
              {data?.attributes?.title}
            </Heading>
            {data?.attributes?.discription.length > 0 ? (
              data?.attributes?.discription.map((ele, index) => (
                <Text
                  key={index}
                  as={'p'}
                  fontFamily={'var(--foundersGrotesk-regular);'}
                  fontSize={['16px', '16px', '16px', '20px', '20px', '20px']}
                  color={'#fff'}
                  fontWeight={'400'}
                  lineHeight={'normal'}
                  letterSpacing={'-2.2%'}
                >
                  {ele.children[0]?.text}
                </Text>
              ))
            ) : (
              <Text color={'#fff'}>No description available.</Text>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HeroBanner;
