import { Box, Heading, Image, Link, Text } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LetsTalk from './LetsWorkToGether';

const Detail = () => {
  const { InspirationDetailID } = useParams(); // Get slug from URL
  const [categoryData, setCategoryData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://cms.wearetenet.com/api/inspirations?populate=deep`
        );
        const result = await response.json();

        // Filter the data to match the subcategory slug
        const filteredCategory = result.data.filter(category =>
          category.attributes?.Subcategories?.some(
            sub => sub.subcategoryDetailCard?.slug === InspirationDetailID
          )
        );

        // Set the data if found
        if (filteredCategory.length > 0) {
          setCategoryData(filteredCategory[0]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [InspirationDetailID]);

  if (!categoryData) {
    return <div>Loading...</div>; // Show loading state
  }

  // Extract the relevant subcategory based on the slug
  const subcategory = categoryData.attributes.Subcategories.find(
    sub => sub.subcategoryDetailCard?.slug === InspirationDetailID
  );

  return (
    <Box>
      {/* Title */}
      <Box
        background={'#111111'}
        textAlign={'center'}
        paddingTop={['104px', '104px', '98px', '98px', '98px']}
        minH={['195px', '195px', '212px', '212px', '212px']}
      >
        <Heading
          as={'h1'}
          fontSize={['36px', '36px', '36px', '48px', '48px', '48px']}
          color={'#FFFFFF'}
          fontFamily={'var(--garnett-medium);'}
          fontWeight={'500'}
          lineHeight={'normal'}
        >
          {subcategory?.Title}
        </Heading>
      </Box>

      {/* Detail Text With Image */}
      <Box
        maxW={['100%', '100%', '100%', '100%', '91%', '91%']}
        margin={'0 auto'}
        padding={['0px 20px', '0px 20px', '0px 20px', '0px 20px', '0px 0px']}
      >
        {/* Wrapper */}
        <Box
          display={['flex']}
          flexDirection={['column', 'column', 'row']}
          gap={['24px', '24px', '24px', '33px', '33px']}
          padding={[
            '80px 0 80px',
            '80px 0 80px',
            '80px 0 80px',
            '66px 0 150px',
          ]}
          justifyContent={'space-between'}
        >
          <Box w={['100%', '100%', '100%', '61%', '61%', '61%']}>
            {subcategory?.Image?.data?.[0]?.attributes?.url && (
              <Image
                aspectRatio={'1.62741312741'}
                width={'100%'}
                height={'auto'}
                objectFit={'inherit'}
                src={`https://cms.wearetenet.com${subcategory.Image.data[0].attributes.url}`}
              />
            )}
          </Box>

          <Box width={['100%', '100%', '100%', '36%', '36%', '36%']}>
            <Text
              as="p"
              fontSize={['18px', '18px', '18px', '20px', '20px', '20px']}
              color={'#333333'}
              fontFamily={'var(--foundersGrotesk-regular);'}
              fontWeight={'400'}
              lineHeight={'normal'}
              paddingBottom={['32px']}
            >
              {
                subcategory?.subcategoryDetailCard
                  ?.SubCategoryCardDiscription[0]?.children[0]?.text
              }
            </Text>

            {/* Buttons */}
            <Box
              display={'flex'}
              flexWrap={'wrap'}
              gap={['15px', '15px', '15px', '20px']}
            >
              {subcategory?.subcategoryDetailCard?.SubCategoryCardLinkOneText?.trim() && (
                <Link
                  href={
                    subcategory?.subcategoryDetailCard?.SubCategoryCardLinkOneLink?.trim()
                      ? subcategory.subcategoryDetailCard
                          .SubCategoryCardLinkOneLink
                      : 'javascript:void(0)'
                  }
                  fontWeight={400}
                  fontFamily={'var(--foundersGrotesk-regular);'}
                  backgroundColor={'#0057FF'}
                  color={'#fff'}
                  height={'33px'}
                  display={'inline-flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  padding={'16px 11px 11px 11px'}
                >
                  {subcategory.subcategoryDetailCard.SubCategoryCardLinkOneText}
                </Link>
              )}

              {subcategory?.subcategoryDetailCard?.SubCategoryCardLinkTwoText?.trim() && (
                <Link
                  href={
                    subcategory?.subcategoryDetailCard?.SubCategoryCardLinkTwoLink?.trim()
                      ? subcategory.subcategoryDetailCard
                          .SubCategoryCardLinkTwoLink
                      : 'javascript:void(0)'
                  }
                  fontWeight={400}
                  fontFamily={'var(--foundersGrotesk-regular);'}
                  backgroundColor={'#ffff'}
                  border={'1px solid #0057FF'}
                  color={'#0057FF'}
                  height={'33px'}
                  display={'inline-flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  padding={'16px 11px 11px 11px'}
                >
                  {subcategory.subcategoryDetailCard.SubCategoryCardLinkTwoText}
                </Link>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Lets Talk */}
      <LetsTalk />
    </Box>
  );
};

export default Detail;
