import React from 'react'
import Banner from './Banner';
import DataGrid from './DataGrid';
import './Custome.css'
 

const InspirationLanding = () => {
  return (
    <>
      <Banner />
      <DataGrid/>
    </>
  );
}
export default InspirationLanding
