
import { Box, Container, Flex, Text, Image } from '@chakra-ui/react';
import BannerImg from '../../../assets/inspiration/Landing/BannerImg.webp'
import Breadcrumb from './Breadcrumb';
import handImg from '../../../assets/inspiration/Landing/banner/bannerHand.svg'
 
const HeroBanner = () => {
 
  const bannerTitle = {
    fontSize: ['36px', '36px', '48px', '58px', '68px', '68px'],
    color: '#fff',
    fontFamily: 'var(--garnett-semibold)',
    fontWeight: 600,
    lineHeight: ['normal'],
  };
  return (
    <Box
      id="hero-section"
      padding={[
        '51px 0 62px',
        '51px 0 62px',
        '51px 0 62px',
        '49px 0 78px',
        '49px 0 78px',
        '49px 0 78px',
      ]}
      overflow="hidden"
      position="relative"
      backgroundColor={'#0d0e4d'}
    >
      <Container
        maxWidth={['100%', '100%', '100%', '91%', '91%', '91%']}
        m="0 auto"
        zIndex={2}
        position="relative"
        padding={['0 20px', '0 20px', '0 20px', '0', '0', '0']}
      >
        <Breadcrumb />
        <Box
          display={['block', 'block', 'block', 'flex', 'flex', 'flex']}
          justifyContent={'space-between'}
          paddingTop={['0px', '0px', '0px', '80px', '80px', '100px']}
        >
          <Box
            width={['100%', '100%', '100%', '47%', '47%', '47%']}
            display="flex"
            alignItems="center"
            position={['relative']}
          >
            <Box mb={['60px', '60px', '70px', '0px', '0px', '0px']}>
              <Text as="h1" sx={bannerTitle}>
                Design inspiration collections
                <Box display={['inline-block']} paddingLeft={'10px'}>
                
                  <Image
                    width={['28px', '28px', '28px', '55px', '55px', '55px']}
                    height={['31px', '31px', '31px', '61px', '61px', '61px']}
                    src={handImg}
                  />
                </Box>
              </Text>
            </Box>
          </Box>
          <Box
            width={['100%', '100%', '100%', '38%', '38%', '38%']}
            position={['relative']}
            left={['0', '0', '0', '15px', '15px', '15px']}
          >
            <Image
              src={BannerImg}
              w="100%"
              aspectRatio={1.7792642140468227}
              objectFit="cover"
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HeroBanner;
