import { Box, Link } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axios from 'axios';
const Breadcrumb = () => {
    const { InspirationID } = useParams();
    const [data, setData] = useState(null); // Change to null for better initial state
    useEffect(() => {
      const fetchData = async () => {
        try {
          const resp = await axios.get(
            `https://cms.wearetenet.com/api/inspirations?populate=deep&filters[Slug]=${InspirationID}`
          );
          if (resp.data.data.length > 0) {
            setData(resp.data.data[0]); // Get the first matching item
          } else {
            console.error('No data found for this LocationId');
          }
        } catch (err) {
          console.error('Data Not Found: ' + err);
        }
      };
      fetchData();
    }, [InspirationID]);
    const breadCrumb ={
        fontSize: ['14px', '14px', '14px', '16px', '16px', '16px'],
        fontFamily: 'var(--foundersGrotesk-regular)',
        fontWeight: '400',
        lineHeight: 'normal',
        color: '#fff',
        display:'inline-flex',
        letterSpacing:'-0.352px'
      }
  return (
    <Box display={['none', 'none', 'none', 'Flex', 'Flex', 'Flex']}>
      <Link sx={breadCrumb} href="/">
        Home
      </Link>
      <Box mx={'6px'} pb={'4px'}>
        <svg
          mx={'6px'}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path d="M6 12L10 8L6 4" stroke="white" stroke-linecap="square" />
        </svg>
      </Box>
      <Link sx={breadCrumb}>Inspirations</Link>
      <Box mx={'6px'} pb={'4px'}>
        <svg
          mx={'6px'}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path d="M6 12L10 8L6 4" stroke="white" stroke-linecap="square" />
        </svg>
      </Box>
      <Link sx={breadCrumb}> {data?.attributes?.title}</Link>
    </Box>
  );
}
export default Breadcrumb
