import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useParams } from 'react-router-dom';
import InspirationLanding from '../components/Inspiration/Landing/InspirationLanding';
import InspirationSubCategaryPage from '../components/Inspiration/SubCategory/InspirationSubCategory';
import InspirationDetailPage from '../components/Inspiration/Detail/Detail';
// Lazy load components
const Home = lazy(() => import('../components/Home/Home'));
const About = lazy(() => import('../components/About/About'));
const Error404 = lazy(() => import('../components/Error404/Error404'));
const CaseStudyPazazz = lazy(() => import('../components/CaseStudy - Pazazz/CaseStudy'));
const CaseStudyStrongr = lazy(() => import('../components/CaseStudy - Strongr/CaseStudy'));
const CaseStudyAngles = lazy(() => import('../components/CaseStudy - Angles/CaseStudy'));
const CaseStudyThinkVastu = lazy(() => import('../components/CaseStudy - ThinkVastu/CaseStudy'));
const CaseStudyIROS = lazy(() => import('../components/CaseStudy - IROS/CaseStudy'));
const Strategy = lazy(() => import('../components/Strategy/strategy'));
const Solution = lazy(() => import('../components/Solutions/Solutions'));
const Work = lazy(() => import('../components/Work/Work'));
const Resources = lazy(() => import('../components/Resources/Resources'));
const Insights = lazy(() => import('../components/Insights/Resources'));
const Guides = lazy(() => import('../components/Guides/Resources'));
const Thoughts = lazy(() => import('../components/Thoughts/Resources'));
const Glossary = lazy(() => import('../components/Glossary/Resources'));
const Updates = lazy(() => import('../components/Updates/Resources'));
const Listenin = lazy(() => import('../components/Listenin/Resources'));
const Service = lazy(() => import('../components/Service/Service/Service'));
const ContactUs = lazy(() => import('../components/ContactUs/ContactUs'));
const Upcoming = lazy(() => import('../components/Upcoming/Upcoming'));
const SingleInsight = lazy(() => import('../components/Blogs - Insight/SingleInsights/InsightSingle'));
const GeneralBlog = lazy(() => import('../components/Blogs - General/SingleBlog/BlogSingle'));
const Partners = lazy(() => import('../components/Partners/Partners'));
const ToolsAndTech = lazy(() => import('../components/ToolsAndTechnologies/ToolsAndTech'));
const WhiteLabel = lazy(() => import('../components/WhiteLabel/WhiteLabel'));
const Career = lazy(() => import('../components/Career/Career'));
const JobDetail = lazy(() => import('../components/Career/JobDetails/JobDetails'));
const ThankYouPage = lazy(() => import('../components/ThankYouPage/ThankYouPage'));
const ThankYouPageTwo = lazy(() => import('../components/ThankYouPageTwo/ThankYouPage'));
const SingleServicePage = lazy(() => import('../components/Service/SingleService/Single'));
const UnderConstruction = lazy(() => import('../components/UnderConstruction/Construction'));
const CaseStudy = lazy(() => import('../components/CaseStudy - Strapi/CaseStudy'));
const SingleDetailPageNew = lazy(() => import('../components/ServicesSingleDetailsPage/SingleService'));
const Research = lazy(() => import('../components/Research - ServicesDetailPage/SingleService'));
const Design = lazy(() => import('../components/Design - ServicesDetailPage/SingleService'));
const Build = lazy(() => import('../components/Build - ServicesDetailPage/SingleService'));
const Growth = lazy(() => import('../components/Growth - ServicesDetailPage/SingleService'));
const SolutionsByOutComesDetailsPage = lazy(() => import('../components/SolutionsByOutComesDetailPage/SolutionsByOutComesDetailsPage'));
const SolutionByIndustryDetailPage = lazy(() => import('../components/SolutionByIndustryDetailPage/SolutionByIndustryDetailPage'));
const ServiceXYindustry = lazy(() => import('../components/ServiceXYIndustry/ServiceXYindustry'));
const LocationArchive = lazy(() => import('../components/Location/Archive/Archive'));
const LocationSingle = lazy(() => import('../components/Location/Single/Single'));
const SitemapGenerator = lazy(() => import('../components/Sitemap/SitemapGenerator'));
const { UsSitemap, GlobalSitemap } = lazy(() => import('../components/Sitemap/UsSitemaps')); // Update the path as necessary
const TermsAndCondtions = lazy(() => import('../components/Terms-and-Conditions/TermsAndCondtion'));
const PrivacyPolicy = lazy(() => import('../components/privacy-policy/TermsAndCondtion'));

const AllRoutes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={null}>
        <Routes>
          <Route path="/" element={<Home region="global" />} />
          <Route path="/:region" element={<RegionWrapper />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/:region/about-us" element={<RegionWrapperAbout />} />
          <Route path="/work/pazazz" element={<CaseStudyPazazz />} />
          <Route path="/work/think-vastu" element={<CaseStudyThinkVastu />} />
          <Route path="/our-strategy" element={<Strategy />} />
          <Route
            path="/:region/our-strategy"
            element={<RegionWrapperStrategy />}
          />
          <Route path="/services" element={<Service />} />
          <Route path="/:region/services" element={<RegionWrapperService />} />
          <Route path="/contact-us" element={<ContactUs region="global" />} />
          <Route
            path="/:region/contact-us"
            element={<RegionWrapperContact />}
          />
          <Route path="/solutions" element={<Solution />} />
          <Route
            path="/:region/solutions"
            element={<RegionWrapperSolutions />}
          />
          <Route path="/work" element={<Work />} />
          <Route path="/:region/work" element={<RegionWrapperWork />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/resources/insights" element={<Insights />} />
          <Route path="/resources/guides" element={<Guides />} />
          <Route path="/resources/glossary" element={<Glossary />} />
          <Route path="/resources/thoughts" element={<Thoughts />} />
          <Route path="/resources/updates" element={<Updates />} />
          <Route path="/resources/listen" element={<Listenin />} />
          <Route path="/404" element={<Error404 />} />
          <Route path="*" element={<Navigate to="/404" />} />
          <Route path="/upcoming" element={<Upcoming />} />
          <Route
            path="/resources/:category/:slug"
            element={<SingleInsight />}
          />
          <Route path="/resources/:slug" element={<GeneralBlog />} />
          <Route path="/work/:slug" element={<CaseStudy />} />
          <Route path="/work/strongr" element={<CaseStudyStrongr />} />
          <Route path="/work/angles" element={<CaseStudyAngles />} />
          <Route path="/work/iros" element={<CaseStudyIROS />} />
          <Route
            path="/partner-with-us"
            element={<Partners region="global" />}
          />
          <Route
            path="/:region/partner-with-us"
            element={<RegionWrapperPartners />}
          />
          <Route path="/tools-and-technologies" element={<ToolsAndTech />} />
          <Route path="/white-label-agency" element={<WhiteLabel />} />
          <Route
            path="/:region/white-label-agency"
            element={<RegionWrapperWhiteLabel />}
          />
          <Route path="/career" element={<Career />} />
          <Route path="/career/:slug" element={<JobDetail />} />
          <Route path="/thankyou" element={<ThankYouPage />} />
          <Route path="/thankyou2" element={<ThankYouPageTwo />} />
          <Route path="/service-detail-page" element={<SingleServicePage />} />
          <Route path="/" element={<UnderConstruction />} />
          <Route path="/single-service-new" element={<SingleDetailPageNew />} />
          <Route path="/research/:slug" element={<Research />} />
          <Route path="/design/:slug" element={<Design />} />
          <Route path="/build/:slug" element={<Build />} />
          <Route path="/growth/:slug" element={<Growth />} />
          <Route
            path="/outcome/:slug"
            element={<SolutionsByOutComesDetailsPage />}
          />
          <Route
            path="/industry/:slug"
            element={<SolutionByIndustryDetailPage />}
          />
          <Route path="/serviceX" element={<ServiceXYindustry />} />
          <Route path="/location-archive" element={<LocationArchive />} />
          <Route path="/location-single" element={<LocationSingle />} />
          <Route path="/sitemap" element={<SitemapGenerator />} />
          <Route path="/us_sitemap.xml" element={<UsSitemap />} />
          <Route path="/global_sitemap.xml" element={<GlobalSitemap />} />
          <Route path="/locations" element={<LocationArchive />} />
          <Route path="/locations/:LocationId" element={<LocationSingle />} />
          <Route path="/terms-of-use" element={<TermsAndCondtions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/inspirations" element={<InspirationLanding />} />
          <Route
            path="/inspirations/:InspirationID"
            element={<InspirationSubCategaryPage />}
          />
          <Route
            path="/inspirations/:InspirationID/:InspirationDetailID"
            element={<InspirationDetailPage />}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

// Region wrapper components remain unchanged

const RegionWrapper = () => {
  const { region } = useParams();
  const validRegions = ['us', 'in', 'ae', 'global', 'uk'];
  if (validRegions.includes(region)) {
    return <Home region={region} />;
  } else {
    return <Navigate to="/404" />;
  }
};

const RegionWrapperAbout = () => {
  const { region } = useParams();
  const validRegions = ['us', 'in', 'ae', 'global', 'uk'];
  if (validRegions.includes(region)) {
    return <About region={region} />;
  } else {
    return <Navigate to="/404" />;
  }
};

const RegionWrapperPartners = () => {
  const { region } = useParams();
  const validRegions = ['us', 'in', 'ae', 'global', 'uk'];

  if (validRegions.includes(region)) {
    return <Partners region={region} />;
  } else {
    return <Navigate to="/404" />;
  }
};

const RegionWrapperWhiteLabel = () => {
  const { region } = useParams();
  const validRegions = ['us', 'in', 'ae', 'global', 'uk'];

  if (validRegions.includes(region)) {
    return <WhiteLabel region={region} />;
  } else {
    return <Navigate to="/404" />;
  }
};

const RegionWrapperStrategy = () => {
  const { region } = useParams();
  const validRegions = ['us', 'in', 'ae', 'global', 'uk'];

  if (validRegions.includes(region)) {
    return <Strategy region={region} />;
  } else {
    return <Navigate to="/404" />;
  }
};

const RegionWrapperSolutions = () => {
  const { region } = useParams();
  const validRegions = ['us', 'in', 'ae', 'global', 'uk'];

  if (validRegions.includes(region)) {
    return <Solution region={region} />;
  } else {
    return <Navigate to="/404" />;
  }
};

const RegionWrapperService = () => {
  const { region } = useParams();
  const validRegions = ['us', 'in', 'ae', 'global', 'uk'];

  if (validRegions.includes(region)) {
    return <Service region={region} />;
  } else {
    return <Navigate to="/404" />;
  }
};

const RegionWrapperContact = () => {
  const { region } = useParams();
  const validRegions = ['us', 'in', 'ae', 'global', 'uk'];

  if (validRegions.includes(region)) {
    return <ContactUs region={region} />;
  } else {
    return <Navigate to="/404" />;
  }
};

const RegionWrapperWork = () => {
  const { region } = useParams();
  const validRegions = ['us', 'in', 'ae', 'global', 'uk'];

  if (validRegions.includes(region)) {
    return <Work region={region} />;
  } else {
    return <Navigate to="/404" />;
  }
};

export default AllRoutes;
