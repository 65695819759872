import { Box, Link, Image } from '@chakra-ui/react';
import icon from "../../assets/Navbar/whatsapp.svg";

const FloatingWhatsAppButton = () => {
    return (
        <Box
            position="fixed"
            bottom={{ base: "5em", md: "5.4em" }}
            sx={{
                right: "0.9em",
                "@media screen and (min-width: 554px)": {
                    right: "0.6em",
                },
            }}
            zIndex="tooltip"
            borderRadius="full"
        >
            <Link
                href="https://api.whatsapp.com/send?phone=971585510094&text=Hi%2C%20I%20would%20like%20to%20speak%20with%20someone."
                isExternal
            >
                <Image
                    src="https://d3us4ynq1qsik6.cloudfront.net/whatsapp.svg"
                    alt="WhatsApp"
                    sx={{
                        width: "2.8em",
                        height: "2.8em",
                        "@media screen and (min-width: 554px)": {
                            width: "3.4em",
                            height: "3.4em",
                        },
                    }}
                />
            </Link>
        </Box>
    );
};

export default FloatingWhatsAppButton;
